import React, {useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import Logo from '../assets/logotype.png';
import { Link } from 'react-router-dom';
import Selected from '../assets/list_selected.png';
import List from '../assets/list.png';
import Slider1 from '../assets/slider_1.jpg';
import ReactPlayer from 'react-player';
import axios from 'axios';
import errorNotify, { successNotify } from '../services/notify';
import { API_URL } from '../http';

const SLF = () => {
  const [checked, setChecked] = useState(1);

  function changeChecked(x) {
    setChecked(x);
  } 

  const [practics, setPractics] = useState(true);
  const [settings, setSettings] = useState([]);
  const [modalVideo, setModalVideo] = useState(true);
  const [buyModal, setBuyModal] = useState(true);
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [surName, setSurName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');      
  const [model, setModel] = useState('1');
  const [typePayment, setTypePayment] = useState(1);
  const fullPayment = 5000000;
  const fullPaymentDecorative = '50 000';
  const fullPaymentDeposit = 4000000;
  const fullPaymentDepositDecorative = '40 000';
  const prePayment = 2500000;
  const prePaymentDecorative = '25 000';
  const prePaymentDeposit = 1500000;
  const prePaymentDepositDecorative = '15 000';
  const depositDecorative = '10 000';
  const deposit = 1000000;
  const courseName = '«Его величество тело»';
  function ShowModalBuy() {
    setBuyModal(!buyModal);
    if(buyModal) document.getElementById("modalPurchase").style.display = "block";
    else document.getElementById("modalPurchase").style.display = "none";
  }
  function showModalVideo() {
    setModalVideo(!modalVideo);
    if(modalVideo) document.getElementById("myVideo").style.display = "block";
    else document.getElementById("myVideo").style.display = "none";
  }
  function closeModalVideo() {
    setModalVideo(!modalVideo);
    if(modalVideo) document.getElementById("myVideo").style.display = "block";
    else document.getElementById("myVideo").style.display = "none";
  }
  const getSettings = async () => {
    const res = await fetch(
      API_URL + `/s`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
       });
    const data = await res.json();
    setSettings(data);
  };


  useEffect(() => {
    getSettings();
  }, [setSettings]);
  const currentYear = new Date().getFullYear();

  function showpractics() {
    setPractics(!practics);
    if(practics) document.getElementById("footerdrop").style.display = "flex";
    else document.getElementById("footerdrop").style.display = "none";
  }

  function initPayment() {
    if(lastName === '' || firstName === '' || surName === '' || phone === '' || email === '') {
      return errorNotify('Заполните контактные данные!');
    }
    if(model === 0) {
      return errorNotify('Выберите тип обучения!');
    }
    let itemAmount;
    let itemName;
    let purchaseOption;
    if(typePayment === 1) if (model === '1') {
      itemAmount = Number(fullPayment);
      itemName = courseName + '';
      purchaseOption = 2;
    }
    if(typePayment === 1) if (model === '2') {
      itemAmount = Number(prePayment);
      itemName = courseName + '(Аквариум)';
      purchaseOption = 2;
    }
    if(typePayment === 2) if (model === '1') {
      itemName = courseName + ' - депозит';
      itemAmount = Number(deposit);
      purchaseOption = 1;
    }
    if(typePayment === 2) if (model === '2') {
      itemName = courseName + '(Аквариум) - депозит';
      itemAmount = Number(deposit);
      purchaseOption = 1;
    }
    var date = new Date();
    var purchaseDate = ("00" + date.getDate()).slice(-2) + '.' + ("00" + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' ' + ("00" + date.getHours()).slice(-2) + ':' + ("00" + date.getMinutes()).slice(-2);
    const formData = new FormData()
    formData.append('lastName', lastName)
    formData.append('firstName', firstName)
    formData.append('surName', surName)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('purchaseType', "2")
    formData.append('itemAmount', itemAmount)
    formData.append('itemName', itemName)
    formData.append('purchaseOption', purchaseOption)
    formData.append('purchaseDate', purchaseDate)
    axios.post(API_URL + '/purchase/create', formData)
    .then(res => {
      window.location.replace(res.data);

    })
    .catch(err => errorNotify(err.response.data.message));
  }

    return (
      <>
          <div id="modalPurchase" className="modal-buy">
            <div className="modal-content">
              <span onClick={() => ShowModalBuy()} className="close">&times;</span>
              <h3 className='modal-feedback-header'>«Его величество тело»</h3>
              <hr className="modal-feedback-hr" />
              <p className="modal-title">Модель оплаты</p>
              { typePayment === 1 ?
              <div className="btn_model_group">
                <button onClick={() => setTypePayment(1)} className="btn-model_fullpayment">Полная оплата</button>
                <button onClick={() => setTypePayment(2)} className="btn-model_prepayment">Внести депозит</button>
              </div>
              : typePayment === 2 ?
              <div className="btn_model_group">
                <button onClick={() => setTypePayment(1)} className="btn-model_fullpayment_alt">Полная оплата</button>
                <button onClick={() => setTypePayment(2)} className="btn-model_prepayment_alt">Внести депозит</button>
              </div>
              : null
              }
              { typePayment === 2 ?
                model === 0 ?
                <div className='disclamer-payment-box'>
                  <p>Невозможно рассчитать стоимость курса, необходимо выбрать модель обучения</p>
                </div>
                :
                <div className='disclamer-payment-box'>
                  <p>Вы вносите депозит в размере 10 000 руб; остаток – {model === '1' ? fullPaymentDepositDecorative : model === '2' ? prePaymentDepositDecorative : null}. Оставшуюся сумму необходимо внести за 2 дня до начала курса</p>
                </div>
                : null
              }
              <input value={lastName} onChange={(e) => setLastName(e.target.value)} className="modal-feedback-input form-size" placeholder="Фамилия" />
              <input value={firstName} onChange={(e) => setFirstName(e.target.value)} className="modal-feedback-input form-size" placeholder="Имя" />
              <input value={surName} onChange={(e) => setSurName(e.target.value)} className="modal-feedback-input form-size" placeholder="Отчество" />
              <input value={phone} onChange={(e) => setPhone(e.target.value)} className="modal-feedback-input form-size" placeholder="Номер телефона" />
              <input value={email} onChange={(e) => setEmail(e.target.value)} className="modal-feedback-input form-size" placeholder="E-Mail" />
              <div className="card_buy-box">
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Курс:</p>
                  <p className='card_buy-confirm-text'>«Его величество тело»</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Место проведения:</p>
                  <p className='card_buy-confirm-text'>Онлайн</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Дата начала:</p>
                  <p className='card_buy-confirm-text'>01.05.2022</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Дата окончания:</p>
                  <p className='card_buy-confirm-text'>05.05.2022</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Ведущий курса:</p>
                  <p className='card_buy-confirm-text'>Елена Эра</p>
                </div>
                <hr className='card_buy-hr' />
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-header'>К оплате:</p>
                  <p className='card_buy-confirm-header'>{typePayment === 1 && model === '1' ? fullPaymentDecorative + ' ₽' : typePayment === 1 && model === '2' ? prePaymentDecorative + ' ₽' : typePayment === 2 ? depositDecorative + ' ₽' : 'Неизвестно'}</p>
                </div>
              </div>
              <button onClick={() => initPayment()} className='modal-btn-submit'>Перейти к оплате</button>
            </div>
          </div>
        <div id="myVideo" className="modal">
          <div className="modal-content-media">
            <span onClick={() => closeModalVideo()} className="close">&times;</span>
            <h3 className='modal-feedback-header' style={{marginBottom: '30px'}}>О курсе</h3>
            {settings.op_link_yt_2 !== '' ?
            <ReactPlayer
                // Disable download button
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}

                // Disable right click
                onContextMenu={e => e.preventDefault()}

                // Your props
                url={settings.op_link_yt_2}
                className="react-player"
                controls
                width="100%"
                height="460px"
            /> 
            :
            null}
          </div>
        </div>
      <section className="desc-landing">
        <div className='desc-background'>
        </div>
        <div>
          <div className="desc-cards">
            <div className="desc-card">
              <h4 className="desc-card-header">О курсе <span>«Его Величество Тело»</span></h4>
              <div className="course-description-slider">
                <p>Представьте, что только взглянув на тело человека как на образ, вы получите доступ к информации о его жизни, образе мышления, его способах решения задач, его способности чувствовать любовь, давать и получать её. <span>«Образ тела» это совокупность единства и настроек «Души и Разума».</span></p>
              </div>
              <div className="course-description-slider-alt">
                <p>Представьте, что прикасаясь особенным образом к телу, вы получаете доступ к его и коллективному бессознательному, <span>становитесь проводником между Душой и Духом.</span></p>
              </div>
              <div className="course-description-slider">
                <p>Инструменты метода «Его Величество Тело» дают возможность в полной мере осознать, что тело это осознанное Существо, в каждой клетке которого, с теми кто знает как его слышать, говорит Вселенная. <span>В каждой клетке содержится информация о пути биологической эволюции до устройства всего мироздания.</span></p>
              </div>
            </div>
          </div>
        </div>

        <h3 className="course-description-about-header"><span>После прохождения курса</span> вы сможете:</h3>
      
        <div className="course-description-about-futures-box">
          <div className='course-description-about-futures-i-left'>
            <div className="course-description-about-futures-header">
              01
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0L10.1607 5.83927L16 8L10.1607 10.1607L8 16L5.83927 10.1607L0 8L5.83927 5.83927L8 0Z" fill="#7788BB"/>
              </svg>
              <p>Открывать доступ к невероятным по масштабам ресурсам энергии.</p>
            </div>
          </div>
          <div className='course-description-about-futures-i'>
            <div className="course-description-about-futures-header header-sm">
              02
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0L10.1607 5.83927L16 8L10.1607 10.1607L8 16L5.83927 10.1607L0 8L5.83927 5.83927L8 0Z" fill="#7788BB"/>
              </svg>
              <p className="course-description-about-futures-header-text-alt">Иметь информацию о том как, когда и при каких обстоятельствах это произошло какое то событие или явление.</p>
            </div>
          </div>
          <div className='course-description-about-futures-i-right'>
            <div className="course-description-about-futures-header text-long">
              03
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0L10.1607 5.83927L16 8L10.1607 10.1607L8 16L5.83927 10.1607L0 8L5.83927 5.83927L8 0Z" fill="#7788BB"/>
              </svg>
              <p>У вас не будет преграды во времени и пространстве.</p>
            </div>
          </div>
        </div>
        <div className="course-description-about-futures-box-2">
          <div className='course-description-about-futures-i-left'>
            <div className="course-description-about-futures-header">
              04
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0L10.1607 5.83927L16 8L10.1607 10.1607L8 16L5.83927 10.1607L0 8L5.83927 5.83927L8 0Z" fill="#7788BB"/>
              </svg>
              <p>Вы получите инструмент,<br />с помощью которого будет найден травматический отпечаток души любого масштаба, где бы не находился его источник.</p>
            </div>
          </div>
          <div className='course-description-about-futures-i'>
            <div className="course-description-about-futures-header header-sm">
              05
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0L10.1607 5.83927L16 8L10.1607 10.1607L8 16L5.83927 10.1607L0 8L5.83927 5.83927L8 0Z" fill="#7788BB"/>
              </svg>
              <p className="course-description-about-futures-header-text-alt">Сможете не только найти, но и научитесь управлять тем, что называют расширением сознания, при котором сами физические тела смогут высвободить гигантский потенциал энергии, удерживаемый ранее.</p>
            </div>
          </div>
        </div>

        <h3 className="header-desc_course">Вы научитесь алгоритму перехода в такие состояния, при котором <span>ограничения ума не будут влиять на доступ к пространству Души и исцелению психологической травмы</span> любого уровня и масштаба</h3>
        <p className="text-desc_course">Эта ли жизни от момента зачатия, детства, либо того что передалось предками и стало генетикой? Многие из ныне живущих людей, хранят память и невостребованные энергии из прошлых воплощений. И не только из-за травматического опыта, но и из за искажения в ценностях, навязанного алгоритма развития общества.</p>

        <div className="circle-desc-group">
          <div className="circle-desc-group_item">
            <p className="circle-desc-group_item_text"><span>Сколько из вас знают, что необычно талантливы и многогранные</span> как личность, но идёт время, но это вами не проявлено в вашей жизни? Как часто вы знаете что достойны лучшего, но довольствуетесь тем что есть, а вернее мечтами о том, как это могло бы быть.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M8 0L10.1607 5.83927L16 8L10.1607 10.1607L8 16L5.83927 10.1607L0 8L5.83927 5.83927L8 0Z" fill="#7788BB"/>
            </svg>
          </div>
          <div className="circle-desc-group_item_alt">
            <p className="circle-desc-group_item_text"><span>Сколько сил вы тратите, чтобы удерживать то, что раздражает</span> или вызывает зависть в других людях? Сколько информация и знаний вы получили, но так и не превратили её в энергию для обеспечения вашей жизни?</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M8 0L10.1607 5.83927L16 8L10.1607 10.1607L8 16L5.83927 10.1607L0 8L5.83927 5.83927L8 0Z" fill="#7788BB"/>
            </svg>
          </div>
        </div>


        <h3 className='course-description-state-header'><span>Практики «Сияющие Руки» получат</span> для себя эффективный инструмент</h3>
        <p className="course-description-text">Он позволяет легко и эффективно работать с детскими травмами, фобиями, паническими атаками, драмами и архитичной памятью цивилизаций.</p>
        <p className="course-description-text_2"><span>Сопровождая другого человека по дороге к сокровищам его Бессознательного</span>, сам практик получает осознание Единства и взаимодействия между видимым и невидимым миром энергий, между клеткой и организмом, между телами человека и живой планетой Земля.</p>
        <div style={{cursor: 'pointer'}} onClick={() => showModalVideo()} className="player">
          <svg width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23 13L0.5 25.9904L0.5 0.00961876L23 13Z" fill="white"/>
          </svg>
        </div>
        <p className="player-text">Елена Эра о курсе «Его величество Тело»</p>
        <h3 className='course-description-state-header'><span>Бессознательное!</span> Что это?</h3>


        <div className='conditions-box'>
          <div className="conditions-box_item">
            <p className='conditions-box_item_text'><span>Практики получат красивый и универсальный способ освобождения физического тела от запечатленной в клетках тяжёлой памяти прошлого</span>, чужого и заставляющего человека страдать. А многие физически переживут опыт рождения вместе с освобождением физического тела для нового варианта будущего, которое человек захочет выбрать для себя.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M8 0L10.1607 5.83927L16 8L10.1607 10.1607L8 16L5.83927 10.1607L0 8L5.83927 5.83927L8 0Z" fill="#7788BB"/>
            </svg>
          </div>
          <div className="conditions-box_item_alt">
            <p className="conditions-box_item_text_alt"><span>Бездна, в которой можно пропасть без вести? Или Божественные и Безграничные возможности для жизни</span>. Как это всё может быть связано с общечеловеческими темами отношений, поиска смыслов и предназначений, качеством здоровья и сексуальностью, деньгами и мечтами о будущей лучшей жизнью?</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M8 0L10.1607 5.83927L16 8L10.1607 10.1607L8 16L5.83927 10.1607L0 8L5.83927 5.83927L8 0Z" fill="#7788BB"/>
            </svg>
          </div>
        </div>

        <h3 className='course-description-state-header'>Место проведения</h3>
        <p className='course-description-state-text-alt'>Проживание и питание оплачивается отдельно</p>

        <div className="slider-info-group">
          <button className="slider-btn" onClick={checked >= 2 ? () => changeChecked(checked - 1) : () => changeChecked(5)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
              <path d="M15.2031 17.4162L8.78646 10.9995L15.2031 4.58282" stroke="#7788BB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
          <img className='slider-ingo-group_img' src={Slider1}/>
          <button className="slider-btn" onClick={checked <= 4 ? () => changeChecked(checked + 1) : () => changeChecked(1)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path d="M7.79102 4.58336L14.2077 11L7.79102 17.4167" stroke="#7788BB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>


        <div className="reviews_list">
          <img className="about_list_i" src={checked === 1 ? Selected : List} onClick={() => changeChecked(1)} />
          <img className="about_list_i" src={checked === 2 ? Selected : List} onClick={() => changeChecked(2)} />
          <img className="about_list_i" src={checked === 3 ? Selected : List} onClick={() => changeChecked(3)} />
          <img className="about_list_i" src={checked === 4 ? Selected : List} onClick={() => changeChecked(4)} />
          <img className="about_list_i" src={checked === 5 ? Selected : List} onClick={() => changeChecked(5)} />
        </div>



        <div className="container">
          <h2 className="dark_header limit-header">Варианты <span> участия и стоимость</span></h2>
          <p className='course-description-state-text-alt info-header'>Данное мероприятие проводится только в оффлайн</p>
          <h2 className="dark_header limit-header header-cost">50 000 руб.</h2>
          <div className="group_circle-btn btn-cost">
            <a onClick={() => ShowModalBuy()}>
              <button className="circle-btn_fill">
                Оплатить
              </button>
            </a>
            <a href="#programs">
              <button className="circle-btn_dashed">
                Орг. информация
              </button>
            </a>
          </div>
        </div>

        <footer>
        <div className="container">
          <div className="footer_block">
            <div className="footer_block-brand">
              <img className="footer_block-logotype" src={Logo} />
              <p>© {currentYear}, Елена Эра. Все права защищены</p>
            </div>
            <div className="sm-footer-info">
              <a>Расписание</a>
              <a onClick={() => showpractics()}>
                Практики
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.0846 4.9585L7.0013 9.04183L2.91797 4.9585" stroke="#1E1B1B" strokeLinecap="round" strokeLinejoin="round"></path></svg>
              </a>
              <div id="footerdrop">
                <Link className="footerdrop-item" to="/shining-hands">- Практики метода «Сияющие руки»</Link>
                <Link className="footerdrop-item" to="/">- Практики метода «Соединение миров»</Link>
              </div>
              <a>Мастера</a>
              <a>Книги</a>
              <a>Блог</a>
            </div>
            <div className="footer_block-info">
              <div className="footer_block-links">
                <Link to="/schedule">Расписание</Link>
                <div className="dropup">
                  <button className="dropupbtn">
                    Практики 
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.0846 4.9585L7.0013 9.04183L2.91797 4.9585" stroke="#1E1B1B" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </button>
                  <div className="dropup-content">
                    <a href="#">Практики метода «Сияющие руки»</a>
                    <a href="#">Практики метода «Соединение миров» </a>
                  </div>
                </div>
                <a href="#3">Мастера</a>
                <a href="#3">Книги</a>
                <a href="#3">Блог</a>
              </div>
              <a className="footer_block-policy" href="#3">Политика конфиденциальонсти</a>
            </div>
          </div>
        </div>
      </footer>
      
      </section>
      </>
    );
};

export default observer(SLF);