import React from 'react';
import {observer} from "mobx-react-lite";

const SMC = () => {
  return (
    <section className="courses">
      <div className="container">
        <h3 className="dark_header_about">Вы слышали, что <span>тело может решать задачи души? </span> Это компенсаторный механизм</h3>
        <p className="dark_text_about">И это создаёт болезненные реакции самого тела, изменяя его физиологию, биохимию, генно морфологические проявления и сам образ тела.</p>
        <p className="dark_text_about_alt">Искажается его восприятие, мировоззрение человека и его лучшие варианты будущего. Его выборы.</p>  
      </div>
    </section>
    );
};

export default observer(SMC);